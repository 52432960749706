import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { CONTRACT_ADDRESS } from "../../constants";
import ownersBox from "../../utils/OwnersBox.json";
import LoadingIndicator from "../LoadingIndicator";

const Packs = ({ setCharacterNFT }) => {
  const [gameContract, setGameContract] = useState();
  const [isPurchasingPack, setIsPurchasingPack] = useState(false);

  useEffect(() => {
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Ethereum object not found");
      return;
    }

    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const gameContract = new ethers.Contract(
      CONTRACT_ADDRESS,
      ownersBox.abi,
      signer
    );

    /*
     * This is the big difference. Set our gameContract in state.
     */
    setGameContract(gameContract);
  }, []);

  useEffect(() => {
    /*
     * Add a callback method that will fire when this event is received
     */
    const onPackOpened = async (sender, tokens, teams) => {
      console.log(
        `PackOpened - sender: ${sender} tokens: ${tokens} teams: ${teams}`
      );
    };

    /*
     * If our gameContract is ready, let's get characters!
     */
    if (gameContract) {
      gameContract.on("PackOpened", onPackOpened);
    }

    return () => {
      if (gameContract) {
        gameContract.off("PackOpened", onPackOpened);
      }
    };
  }, [gameContract]); // eslint-disable-line react-hooks/exhaustive-deps

  const openPack = () => async () => {
    const gameId = 0; //NBA
    const size = 3; //Size 3
    try {
      if (gameContract) {
        setIsPurchasingPack(true);
        console.log("OpeningPack In process");
        const mintTxn = await gameContract.openPack(size, gameId);
        await mintTxn.wait();
        console.log("mintTxn:", mintTxn);
      }
    } catch (error) {
      console.warn("MintCharacterAction Error:", error);
    }
    setIsPurchasingPack(false);
  };

  return (
    <div className="nes-container with-title is-centered">
      <p className="title">Choose a Pack</p>
      <div className="character-item">
        <button
          type="button"
          className="nes-btn"
          onClick={openPack()}
        >{`Open Pack`}</button>
      </div>
      {isPurchasingPack && (
        <div className="loading">
          <div className="indicator">
            <LoadingIndicator />
            <p>Pack Opening in Progress</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Packs;
