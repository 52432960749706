import React, { useEffect, useState } from "react";
import "./App.css";
// import SelectCharacter from "./Components/SelectCharacter";
import Teams from "./Components/Teams";
import Packs from "./Components/Packs";
import LoadingIndicator from "./Components/LoadingIndicator";

const App = () => {
  const [currentAccount, setCurrentAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  /*
   * Start by creating a new action that we will run on component load
   */
  // Actions
  const checkIfWalletIsConnected = async () => {
    /*
     * First make sure we have access to window.ethereum
     */
    const { ethereum } = window;
    try {
      if (!ethereum) {
        console.log("Make sure you have MetaMask!");
        return;
      } else {
        console.log("We have the ethereum object", ethereum);

        const accounts = await ethereum.request({ method: "eth_accounts" });

        if (accounts.length !== 0) {
          const account = accounts[0];
          console.log("Found an authorized account:", account);
          setCurrentAccount(account);
        } else {
          console.log("No authorized account found.");
        }
      }
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  const connectWalletAction = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        alert("Get MetaMask!");
        return;
      }

      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });

      console.log("Connected", accounts[0]);
      setCurrentAccount(accounts[0]);
    } catch (e) {
      console.log(e);
    }
  };

  const renderContent = () => {
    /*
     * Scenario #1
     */
    if (isLoading) {
      return <div className="center"><LoadingIndicator /></div>;
    }
    if (!currentAccount) {
      return (
        <div className="connect-wallet-container">
          <button
            className="nes-btn"
            onClick={connectWalletAction}
          >
            Connect Wallet To Get Started
          </button>
        </div>
      );
      /*
       * Scenario #2
       */
    } else if (currentAccount) {
      return (
        <>
          <Packs />
          <Teams />
        </>
      );
    }
  };

  /*
   * This runs our function when the page loads.
   */
  useEffect(() => {
    setIsLoading(true);
    checkIfWalletIsConnected();
  }, []);

  return (
    <div className="App">
      <div className="main-content">
        <div className="header-container">
          <h1><span>Owners Box</span></h1>
          <p className="sub-text">
            Get rewards every time a team you own wins in real life.
          </p>
        </div>
        {renderContent()}
      </div>
    </div>
  );
};

export default App;
