const CONTRACT_ADDRESS = "0xBbE4de4f1f1AEe16841A2f38cDc18AA9741E4c3A";

const transformTeamsData = (teamsData) => {
  console.log('to', teamsData);
  return {
    id: teamsData.id.toNumber(),
    name: teamsData.name,
    imageURI: teamsData.imageURI,
    location: teamsData.teamsData,
    game: teamsData.game,
    wins: teamsData.wins,
    losses: teamsData.losses,
    ties: teamsData.ties,
    level: teamsData.level,
  };
};

export { CONTRACT_ADDRESS, transformTeamsData };
