import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { CONTRACT_ADDRESS, transformTeamsData } from "../../constants";
import ownersBox from "../../utils/OwnersBox.json";
import "./card.css";

const Teams = ({ setCharacterNFT }) => {
  const [teams, setTeams] = useState([]);
  const [gameContract, setGameContract] = useState();
  const [reloadTeams, setReloadTeams] = useState();

  useEffect(() => {
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Ethereum object not found");
      return;
    }

    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const gameContract = new ethers.Contract(
      CONTRACT_ADDRESS,
      ownersBox.abi,
      signer
    );

    /*
     * This is the big difference. Set our gameContract in state.
     */
    setGameContract(gameContract);
  }, []);

  useEffect(() => {
    const getTeams = async () => {
      try {
        console.log("Getting contract characters to mint");

        /*
         * Call contract to get all mint-able characters
         */
        const teamsTxn = await gameContract.getMyTeams();
        console.log("teamsTxn:", teamsTxn);

        /*
         * Go through all of our characters and transform the data
         */
        const teams = teamsTxn.map((teamsData) =>
          transformTeamsData(teamsData)
        );

        /*
         * Set all mint-able characters in state
         */
        setTeams(teams);
        setReloadTeams(false);
      } catch (error) {
        console.error("Something went wrong fetching teams:", error);
      }
    }

    /*
     * If our gameContract is ready, let's get characters!
     */
    if (gameContract) {
      getTeams();
      console.log(gameContract);
    }
  }, [gameContract, reloadTeams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    /*
     * Add a callback method that will fire when this event is received
     */
    const onPackOpened = async (sender, tokens, teams) => {
      setReloadTeams(true);
    };

    /*
     * If our gameContract is ready, let's get characters!
     */
    if (gameContract) {
      gameContract.on("PackOpened", onPackOpened);
    }

    return () => {
      if (gameContract) {
        gameContract.off("PackOpened", onPackOpened);
      }
    };
  }, [gameContract]); // eslint-disable-line react-hooks/exhaustive-deps


  const renderTeams = () => {
    console.log(teams);
    return teams.map((team, index) => (
      <a href={`https://testnets.opensea.io/assets/${CONTRACT_ADDRESS}/${team.id}`} target="_blank" rel="noreferrer">
      <div className="card" key={team.name}>
        <div className="name-container">
          <p>{team.name}</p>
        </div>
        <img src={team.imageURI} alt={team.name} />
        <div className="level-container">
          <span>
          LEVEL: {team.level}
          </span>
        </div>
      </div>
      </a>
    ));
  };

  return (
    <div className="nes-container with-title is-centered">
      <p className="title">My Teams</p>
      {teams.length > 0 && (
        <div className="cards">{renderTeams()}</div>
      )}
    </div>
  );
};

export default Teams;
